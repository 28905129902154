<template>
  <div id="wode" style="min-height:100vh;">
    <titleLink title="个人中心" :onClick="onClickBack"></titleLink>
    <div style="padding:0 .2rem 0 .2rem">
      <div class="avatar">
        <van-image
          :src="personInfo.user.avatar"
          round
          fit="cover"
          width="100"
          height="100"
          style="box-shadow: 0px 5px 10px 5px rgba(0, 0, 0.7, 0.1);z-index:999"
        />
      </div>
      <card class="info-card">
        <template #content>
          <van-row style=" color: black;margin-top:60px" class="info-row">
            <span style="font-weight: 600">{{personInfo.user.username}}</span>
          </van-row>
          <van-row class="info-row"> 用户手机：{{personInfo.user.mobile}} </van-row>
          <van-row class="info-row"> 性别：{{personInfo.tourist.gender}} </van-row>
          <van-row class="info-row"> 身份证号：{{personInfo.tourist.id_card}} </van-row>
          <van-row class="info-row"> 出生年月：{{personInfo.tourist.birth_date}} </van-row>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import { mapGetters } from "vuex";
import {Dialog} from "vant"
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    card, titleLink
  },
  data() {
    return {
    };
  },
  methods: {
    showQrPopup() {
      this.showQr = true;
    },
    sign_out() {
      const _self = this
      Dialog.confirm({
        title: "操作提醒",
        message: "退出当前账号？"
      }).then(() => {
        _self.$store.dispatch('wode/resetState')
        _self.$store.dispatch('travel_list/resetState')
        _self.$router.push("/login");
      })
    },
    onClickBack() {
      this.$router.push({ path: "/home" });
    },

  },
  computed: {
    ...mapGetters("wode", {
      personInfo: "getPersonInfo",
    }),
  },
  created() {
    this.$store.dispatch("wode/getPersonInfo");
  },
};
</script>

<style scoped>
.info-card {
  margin-top: -50px;
  font-size: 14px;
  color: grey;
  text-align: center;
}
.info-row {
  margin-top: 10px;
}
.avatar {
  margin-top: 80px;
  text-align: center;
  width: 100%;
  display: block;
  z-index: 999;
}
</style>